import { Button, Flex, FormControl, FormLabel, HStack, Input, Spacer, Textarea, useToast, VStack } from '@chakra-ui/react'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { useGetCurrentUserQuery, useGetEventsQuery, useGetRequestsQuery, usePutRequestMutation } from '../redux/reducer/api'

export const RequestForm: React.FC = () => {
  const toast = useToast()
  const { data: user, isLoading: isLoadingCurrentUser } = useGetCurrentUserQuery()
  const [putRequest, { isLoading, isSuccess, isError }] = usePutRequestMutation()
  const { refetch: refetchRequests } = useGetRequestsQuery({ status: "pending" })
  const { refetch: refetchEvents } = useGetEventsQuery()

  const [type_, setType_] = useState<"exile" | "picnic">("exile")
  const [start, setStart] = useState<string>(DateTime.now().toFormat("yyyy-MM-dd"))
  const [end, setEnd] = useState<string>(DateTime.now().toFormat("yyyy-MM-dd"))
  const [description, setDesciption] = useState<string>("")

  useEffect(() => {
    if (!isLoading) {
      // pass
    }

    if (!isLoading && isSuccess) toast({
      title: '신청 완료',
      description: '신청되었습니다!',
      status: 'success',
      duration: 9000,
      isClosable: true
    })

    if (!isLoading && isError) toast({
      title: '신청 실패',
      description: '신청 중에 오류가 발생하였습니다.',
      status: 'error',
      duration: 9000,
      isClosable: true
    })
  }, [isLoading])

  return (
    <VStack spacing={3} align="normal">
      <div>
        <FormControl display={{ base: 'block', md: 'none' }} mt={0}>
          <FormLabel>시작 시각</FormLabel>
          <Input
            variant="flushed"
            type={type_ === 'exile' ? "date" : "datetime-local"}
            size="sm"
            value={start}
            onChange={(e) => {
              setStart(e.target.value)
            }}
          />
        </FormControl>
        <FormControl display={{ base: 'block', md: 'none' }} mt={3}>
          <FormLabel>종료 시각</FormLabel>
          <Input
            variant="flushed"
            type={type_ === 'exile' ? "date" : "datetime-local"}
            size="sm"
            value={end}
            onChange={(e) => {
              setEnd(e.target.value)
            }}
          />
        </FormControl>
        <Flex display={{ base: 'none', md: 'flex' }}>
          <FormControl>
            <FormLabel>시작 시각</FormLabel>
            <Input
              variant="flushed"
              type={type_ === 'exile' ? "date" : "datetime-local"}
              size="sm"
              value={start}
              onChange={(e) => {
                setStart(e.target.value)
              }}
            />
          </FormControl>
          <FormControl ml={3}>
            <FormLabel>종료 시각</FormLabel>
            <Input
              variant="flushed"
              type={type_ === 'exile' ? "date" : "datetime-local"}
              size="sm"
              value={end}
              onChange={(e) => {
                setEnd(e.target.value)
              }}
            />
          </FormControl>
        </Flex>
      </div>
      <FormControl>
        <FormLabel>신청 사유</FormLabel>
        <Textarea
          variant="flushed" resize="none"
          value={description} onChange={(e) => { setDesciption(e.target.value) }}
        />
      </FormControl>
      <Flex>
        <HStack spacing={3}>
          <Button
            variant="solid" size="sm"
            colorScheme={type_ === "exile" ? "purple" : undefined}
            onClick={() => {
              setStart(DateTime.now().toFormat("yyyy-MM-dd"))
              setEnd(DateTime.now().toFormat("yyyy-MM-dd"))
              setType_("exile")
            }}
          >
            퇴사
          </Button>
          <Button
            variant="solid" size="sm"
            colorScheme={type_ === "picnic" ? "purple" : undefined}
            onClick={() => {
              setStart(DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm:ss"))
              setEnd(DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm:ss"))
              setType_("picnic")
            }}
          >
            외출
          </Button>
        </HStack>
        <Spacer />
        <HStack spacing={3}>
          <Button
            variant="solid" size="sm"
            onClick={() => {
              setType_("exile")
              setStart(DateTime.now().toFormat("yyyy-MM-dd"))
              setEnd(DateTime.now().toFormat("yyyy-MM-dd"))
              setDesciption("")
            }}
          >
            취소
          </Button>
          <Button
            variant="solid" colorScheme="purple" size="sm"
            disabled={isLoading || isLoadingCurrentUser || start > end}
            onClick={() => {
              if (!user) return

              putRequest({
                event: {
                  uid: user.id,
                  type: type_,
                  start: new Date(Date.parse(start)),
                  end: new Date(Date.parse(end))
                },
                description
              })

              refetchEvents()
              refetchRequests()

              setType_("exile")
              setStart(DateTime.now().toFormat("yyyy-MM-dd"))
              setEnd(DateTime.now().toFormat("yyyy-MM-dd"))
              setDesciption("")
            }}
          >
            신청
          </Button>
        </HStack>
      </Flex>
    </VStack >
  )
}
