import { Button, useToast } from "@chakra-ui/react";
import React from "react";
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline, GoogleLogout } from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { GOOGLE_CLIENT_ID } from "../lib/predefined";
import { RootState } from "../redux/reducer";
import { useGetCurrentUserQuery } from "../redux/reducer/api";
import { resetCredential, setCredential } from "../redux/reducer/credential";

function isResponseOnline(response: GoogleLoginResponse | GoogleLoginResponseOffline): response is GoogleLoginResponse {
  return 'googleId' in response && response.code === undefined
}

export const GoogleButton: React.FC = () => {
  const toast = useToast()
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.credential)
  const { refetch } = useGetCurrentUserQuery()

  const loginSuccess = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    console.log("onSuccess")
    if (isResponseOnline(response)) {
      dispatch(setCredential(response))
      refetch() // 아무튼 현재 유저 가져오는거 다시 하셈
      toast({
        title: '로그인 완료',
        description: '로그인에 성공하였습니다!',
        status: 'success',
        duration: 9000,
        isClosable: true
      })
    }
  }
  
  const loginFailure = () => {
    toast({
      title: '로그인 실패',
      description: '로그인에 실패하였습니다. 회원가입 여부를 확인해주세요.',
      status: 'error',
      duration: 9000,
      isClosable: true
    })
  }
  
  const logoutSuccess = () => {
    dispatch(resetCredential())
    refetch()
    toast({
      title: '로그아웃 완료',
      description: '성공적으로 로그아웃하였습니다.',
      status: 'info',
      duration: 9000,
      isClosable: true
    })
  }
  
  const logoutFailure = () => {
    toast({
      title: '로그아웃 실패',
      description: '로그아웃에 실패하였습니다. 페이지를 새로고침해 보세요.',
      status: 'error',
      duration: 9000,
      isClosable: true
    })
  }

  if (!user) {
    return <GoogleLogin
      clientId={GOOGLE_CLIENT_ID}
      render={renderProps => (
        <Button variant="solid" colorScheme="purple" size="sm" disabled={renderProps.disabled} onClick={renderProps.onClick}>
          로그인
        </Button>
      )}
      accessType="online"
      hostedDomain="sshs.hs.kr" // 설곽 계정으로 로그인 강제
      onSuccess={loginSuccess}
      onFailure={loginFailure}
      cookiePolicy={'single_host_origin'}
      isSignedIn={true} // 새로고침 후에 자동 로그인
      prompt="consent" // 항상 refresh_token을 주도록 강제
    />
  } else {
    return <GoogleLogout
      clientId={GOOGLE_CLIENT_ID}
      render={renderProps => (
        <Button variant="outline" colorScheme="purple" size="sm" disabled={renderProps.disabled} onClick={renderProps.onClick}>
          로그아웃
        </Button>
      )}
      onLogoutSuccess={logoutSuccess}
      onFailure={logoutFailure}
    />
  }
}