import { Button, Flex, HStack, Skeleton, Spacer, useToast, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { User, UserApplied } from '../lib/types'
import { useGetPlacesQuery, usePostApplyMutation } from '../redux/reducer/api'
import { PlaceSelector } from './PlaceSelector'

type Props = {
  users: (User & UserApplied)[],
  isFetching: boolean,
  onSuccess?: () => void
}

export const Application: React.FC<Props> = (props: Props) => {
  const toast = useToast()
  const { refetch: refetchPlaces } = useGetPlacesQuery()
  const [apply, { isLoading, isSuccess, isError }] = usePostApplyMutation()
  const [place1, setPlace1] = useState<string | undefined>(undefined)
  const [place2, setPlace2] = useState<string | undefined>(undefined)

  const { users, isFetching, onSuccess } = props

  useEffect(() => {
    if (!isLoading) {
      if (onSuccess) onSuccess()
      refetchPlaces()
    }

    if (!isLoading && isSuccess) toast({
      title: '저장 완료',
      description: '저장되었습니다!',
      status: 'success',
      duration: 9000,
      isClosable: true
    })

    if (!isLoading && isError) toast({
      title: '저장 실패',
      description: '저장 중에 오류가 발생하였습니다.',
      status: 'error',
      duration: 9000,
      isClosable: true
    })
  }, [isLoading])

  return (
    <Skeleton isLoaded={!isFetching}>
      <VStack spacing={3} align='normal'>
        <PlaceSelector
          users={users}
          place1={place1} setPlace1={setPlace1}
          place2={place2} setPlace2={setPlace2}
        />
        <Flex>
          <Spacer />
          <HStack spacing={3}>
            <Button
              variant="solid" size="sm"
              onClick={() => {
                setPlace1(undefined)
                setPlace2(undefined)
              }}
            >
              취소
            </Button>
            <Button
              variant="solid" colorScheme="purple" size="sm"
              disabled={(place1 === undefined && place2 === undefined) || users.length === 0}
              isLoading={isLoading}
              onClick={() => {
                apply({
                  uids: users.map(user => user.id),
                  application: {
                    1: place1,
                    2: place2
                  }
                })
                setPlace1(undefined)
                setPlace2(undefined)
              }}
            >
              저장
            </Button>
          </HStack>
        </Flex>
      </VStack>
    </Skeleton>
  )
}