import { chakra, Container, Skeleton, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Application } from '../components/Application'
import { Navbar } from '../components/Navbar'
import { StudentView } from '../components/StudentView'
import { isStudent, removeUndefined } from '../lib/helper'
import { Student, User, UserApplied } from '../lib/types'
import { useGetCurrentUserQuery, useGetFriendsQuery } from '../redux/reducer/api'

export const Apply: React.FC = () => {
  const navigate = useNavigate()
  const { data: user, isLoading: isLoadingCurrentUser, refetch: refetchCurrentUser, isError: isErrorCurrentUser } = useGetCurrentUserQuery()
  const { data: invFriends, isLoading: isLoadingInvFriends, refetch: refetchInvFriends } = useGetFriendsQuery({ to: user?.id || "" })
  const [selected, setSelected] = useState<Set<Student & User & UserApplied>>(new Set())

  useEffect(() => {
    refetchCurrentUser()
    refetchInvFriends()
  }, [])

  useEffect(() => {
    if (isLoadingCurrentUser) return
    if (user === undefined || isErrorCurrentUser) {
      navigate('/')
    }
  }, [user])

  return (
    <div>
      <Navbar active="apply" />
      <Container maxW='container.lg' px={4} py={4}>
        <VStack spacing={7} align="normal">
          <VStack spacing={3} align="normal">
            <chakra.h2 fontWeight="bold" fontSize="lg">
              대상 선택
            </chakra.h2>
            <Skeleton isLoaded={!isLoadingCurrentUser && !isLoadingInvFriends}>
              <StudentView
                students={removeUndefined([...(invFriends || []), user && isStudent(user) ? user : undefined])}
                select={{ selected, setSelected }}
              />
            </Skeleton>
          </VStack>
          <VStack spacing={3} align="normal">
            <chakra.h2 fontWeight="bold" fontSize="lg">
              장소 선택
            </chakra.h2>
            <Application
              users={[...selected.values()]}
              isFetching={isLoadingInvFriends}
              onSuccess={() => {
                refetchCurrentUser()
                refetchInvFriends()
                setSelected(new Set())
              }}
            />
          </VStack>
        </VStack>
      </Container>
    </div>
  )
}