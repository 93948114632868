import { Container, Skeleton, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Navbar } from '../components/Navbar'
import { PDFButton } from '../components/PDFButton'
import { StudentView } from '../components/StudentView'
import { useGetAllStudentsQuery, useGetCurrentUserQuery } from '../redux/reducer/api'

export const Status: React.FC = () => {
  const navigate = useNavigate()
  const { data: user, isLoading: isLoadingCurrentUser, isError } = useGetCurrentUserQuery()
  const { data: students, isLoading: isLoadingStudents, refetch } = useGetAllStudentsQuery()

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (isLoadingCurrentUser) return
    if (user === undefined || isError) {
      navigate('/')
    }
  }, [user])

  return (
    <div>
      <Navbar active="status" />
      <Container maxW='container.lg' px={4} py={4}>
        <VStack spacing={7} align="normal">
          <PDFButton /> 
          <Skeleton isLoaded={!isLoadingStudents}>
            {students && <StudentView students={students} />}
          </Skeleton>
        </VStack>
      </Container>
    </div>
  )
}