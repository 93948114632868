import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  chakra, Skeleton,
  Table,
  Tbody,
  Th,
  Thead,
  Tr, useBreakpointValue, VStack, Wrap
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Place } from "../../lib/types";
import { useGetPlacesQuery } from "../../redux/reducer/api";
import { AddPlaceItem, PlaceItem } from "./PlaceItem";

export const PlaceSetting: React.FC = () => {
  const { data: places, isLoading, refetch } = useGetPlacesQuery()
  const size = useBreakpointValue({ base: 'sm', md: 'md' })
  const [period, setPeriod] = useState<1 | 2>(1)
  const placeMap = new Map<string, Place[]>()

  places?.forEach(place => {
    placeMap.set(place.position.building, [...(placeMap.get(place.position.building) || []), place])
  })

  return (
    <Skeleton isLoaded={!isLoading}>
      <VStack spacing={3} align='normal'>
        <Wrap spacing={3}>
          <Button
            variant="solid" size="sm"
            colorScheme={period === 1 ? "purple" : undefined}
            onClick={() => setPeriod(1)}
          >
            1교시
          </Button>
          <Button
            variant="solid" size="sm"
            colorScheme={period === 2 ? "purple" : undefined}
            onClick={() => setPeriod(2)}
          >
            2교시
          </Button>
        </Wrap>

        <Accordion allowToggle allowMultiple>
          <AccordionItem></AccordionItem>
          {[...placeMap.entries()].map(([building, placesin]) => (
            <AccordionItem key={building}>
              <chakra.h2>
                <AccordionButton>
                  <Box flex='1' textAlign='left'>
                    {building}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </chakra.h2>
              <AccordionPanel pb={3}>
                <Table variant="simple" borderWidth="1px" size={size}>
                  <Thead>
                    <Tr>
                      <Th>층</Th>
                      <Th>이름</Th>
                      <Th>짧은이름</Th>
                      <Th>권한</Th>
                      <Th isNumeric>인원</Th>
                      <Th isNumeric>정원</Th>
                      <Th>관리</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {placesin.map(place => {
                      return <PlaceItem place={place} period={period} key={place.name} refetch={refetch} />
                    })}
                    <AddPlaceItem period={period} key={"장소추가"} building={building} refetch={refetch} />
                  </Tbody>
                </Table>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </VStack>
    </Skeleton>
  )
}
