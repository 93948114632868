import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { stringify } from 'qs'
import { RootState } from '.'
import { isStudent, sanitizeEvent } from '../../lib/helper'
import { BaseApplied, Event, Place, Request, Student, User, UserApplied } from '../../lib/types'

// eslint-disable-next-line
const serialize = (obj: any) => stringify(obj, { arrayFormat: "repeat" })

export const seatApi = createApi({
  reducerPath: 'seatApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.sshs-pebble.dev/seat/',
    prepareHeaders: (headers, { getState }) => {
      const access_token = (getState() as RootState).credential.access_token
      headers.set('Authorization', `Bearer ${access_token}`)
      headers.set('Access-Control-Allow-Origin', '*')
      return headers
    }
  }),
  endpoints: (builder) => ({
    getCurrentUser: builder.query<User & UserApplied, void>({
      query: () => `user`,
      transformResponse: (response: { count: number, users: (User & UserApplied)[] }) => {
        return response.users[0]
      }
    }),
    getAllStudents: builder.query<(User & Student & UserApplied)[], void>({
      query: () => `user?${serialize({ grade: 0, class_: 0, number_: 0 })}`,
      transformResponse: (response: { count: number, users: (User & UserApplied)[] }) => {
        return response.users.filter(isStudent)
      }
    }),
    getFriends: builder.query<(User & Student & UserApplied)[], { from: string } | { to: string }>({
      query: (arg) => `friend?${serialize(arg)}`,
      transformResponse: (response: { count: number, users: (User & UserApplied)[] }) => {
        return response.users.filter(isStudent)
      }
    }),
    getRequests: builder.query<Request[], { status?: string }>({
      query: (arg) => `request?${serialize(arg)}`,
      transformResponse: (response: { count: number, requests: Request[] }) => {
        return response.requests.map(request => ({ ...request, event: sanitizeEvent(request.event) }))
      }
    }),
    getAdminRequests: builder.query<Request[], void>({
      query: () => `admin/request`,
      transformResponse: (response: { count: number, requests: Request[] }) => {
        return response.requests
      }
    }),
    getPlaces: builder.query<Place[], void>({
      query: () => 'place',
      transformResponse: (response: { count: number, places: Place[] }) => {
        return response.places
      }
    }),
    getEvents: builder.query<Event[], void>({
      query: () => 'event',
      transformResponse: (response: { count: number, events: Event[] }) => {
        return response.events.map(sanitizeEvent)
      }
    }),
    postApply: builder.mutation<void, { uids: string[], application: BaseApplied }>({
      query: (body) => ({
        url: `apply`,
        method: 'POST',
        body
      })
    }),
    putPlaces: builder.mutation<void, Place>({
      query: (body) => ({
        url: `place`,
        method: 'PUT',
        body
      }),
    }),
    patchPlaces: builder.mutation<void, Place>({
      query: (body) => ({
        url: `place`,
        method: 'PATCH',
        body
      }),
    }),
    deletePlaces: builder.mutation<void, { name: string }>({
      query: (body) => ({
        url: `place`,
        method: 'DELETE',
        body
      }),
    }),
    putFriends: builder.mutation<void, string[]>({
      query: (uids) => ({
        url: `friend`,
        method: 'PUT',
        body: { to: uids }
      })
    }),
    putRequest: builder.mutation<void, Omit<Request, "id" | "timestamp" | "status">>({
      query: (body) => ({
        url: `request`,
        method: 'PUT',
        body
      })
    }),
    postRequest: builder.mutation<void, { body: { action: "approve" | "reject" }, query: string }>({
      query: ({ body, query }) => {
        return ({
          url: `request/` + query,
          method: 'POST',
          body
        })
      }
    }),
    deleteFriends: builder.mutation<void, string[]>({
      query: (uids) => ({
        url: `friend`,
        method: 'DELETE',
        body: { to: uids }
      })
    }),
  })
})

export const coreApi = createApi({
  reducerPath: 'coreApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.sshs-pebble.dev/core/',
    prepareHeaders: (headers, { getState }) => {
      const access_token = (getState() as RootState).credential.access_token
      headers.set('Authorization', `Bearer ${access_token}`)
      headers.set('Access-Control-Allow-Origin', '*')
      return headers
    }
  }),
  endpoints: (builder) => ({ 
    postRole: builder.mutation<void, { id:string, add: string[], del: string[] }>({
      query: (body) => ({
        url: `role`,
        method: 'POST',
        body
      })
    }),
  })
})

export const {
  useGetCurrentUserQuery,
  useGetAllStudentsQuery,
  useGetFriendsQuery,
  useGetRequestsQuery,
  useGetAdminRequestsQuery,
  useGetPlacesQuery,
  useGetEventsQuery,
  usePostApplyMutation,
  usePutPlacesMutation,
  usePatchPlacesMutation,
  useDeletePlacesMutation,
  usePutFriendsMutation,
  usePutRequestMutation,
  usePostRequestMutation,
  useDeleteFriendsMutation
} = seatApi

export const { 
  usePostRoleMutation
} = coreApi