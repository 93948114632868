import { Checkbox, Select, Table, Tbody, Td, Text, Th, Thead, Tr, useBreakpointValue, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { composeAltCode, stringComparator } from "../lib/helper";
import { Student, User, UserApplied } from "../lib/types";

type Props = {
  students: (User & Student & UserApplied)[],
  select?: {
    selected: Set<User & Student & UserApplied>,
    setSelected: React.Dispatch<React.SetStateAction<Set<User & Student & UserApplied>>>
  }
}

export const StudentView: React.FC<Props> = (props: Props) => {
  const [sorter, setSorter] = useState<string>("altcode")
  const size = useBreakpointValue({ base: 'sm', md: 'md' })
  const { students: studentsRaw, select } = props

  const students = [...studentsRaw]
  students.sort((l, r) => {
    if (sorter === "" || sorter === "altcode") return stringComparator(composeAltCode(l), composeAltCode(r))
    if (sorter === "place1") return stringComparator(l.applied[1] || l.default[1], r.applied[1] || r.default[1])
    if (sorter === "place2") return stringComparator(l.applied[2] || l.default[2], r.applied[2] || r.default[2])
    return stringComparator(l.name, r.name)
  })

  return (
    <VStack spacing={3}>
      <Select
        variant="flushed" size={size}
        value={sorter}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setSorter(e.target.value)
        }}
      >
        <option value="altcode">학번순</option>
        <option value="name">이름순</option>
        <option value="place1">1교시 장소순</option>
        <option value="place2">2교시 장소순</option>
      </Select>
      <Table variant="simple" borderWidth="1px" size={size}>
        <Thead>
          <Tr>
            <Th>학번</Th>
            <Th>이름</Th>
            <Th>1교시</Th>
            <Th>2교시</Th>
            {select && <Th isNumeric>선택</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {students.map(student => (
            <Tr key={student.id}>
              <Td>{composeAltCode(student)}</Td>
              <Td>{student.name}</Td>
              <Td><Text isTruncated>{student.applied[1] || student.default[1]}</Text></Td>
              <Td><Text isTruncated>{student.applied[2] || student.default[2]}</Text></Td>
              {select &&
                <Td isNumeric>
                  <Checkbox
                    colorScheme="purple"
                    isChecked={select.selected.has(student)}
                    onChange={() => {
                      select.setSelected((selected) => {
                        const next = new Set(selected)

                        if (next.has(student)) next.delete(student)
                        else next.add(student)

                        return next
                      })
                    }}
                  />
                </Td>
              }
            </Tr>
          ))}
        </Tbody>
      </Table>
    </VStack>
  )
}