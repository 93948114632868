import { chakra, Container, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Navbar } from '../../components/Navbar'
import { useGetCurrentUserQuery } from '../../redux/reducer/api'
import { PlaceSetting } from './PlaceSetting'

export const Place: React.FC = () => {
  const { data: user, isLoading, isError } = useGetCurrentUserQuery()
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoading) return
    if (user === undefined || !user.roles.includes('admin') || isError) {
      navigate('/')
    }
  }, [user])

  return (
    <div>
      <Navbar active="place" />
      <Container maxW='container.lg' px={4} py={4}>
        <VStack spacing={7} align="normal">
          <chakra.h2 fontWeight="medium" fontSize="lg">
            장소 관리
          </chakra.h2>
          <PlaceSetting />
        </VStack>
      </Container>
    </div>
  )
}