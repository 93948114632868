import { Button, chakra, Container, Flex, HStack, Skeleton, Spacer, useToast, VStack } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Navbar } from '../components/Navbar'
import { StudentDropdown } from '../components/StudentDropdown'
import { StudentView } from '../components/StudentView'
import { removeUndefined } from '../lib/helper'
import { Student, User, UserApplied } from '../lib/types'
import { useDeleteFriendsMutation, useGetAllStudentsQuery, useGetCurrentUserQuery, useGetFriendsQuery, usePutFriendsMutation } from '../redux/reducer/api'

export const Friend: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { data: user, isLoading: isLoadingCurrentUser, refetch: refetchCurrentUser, isError: isErrorCurrentUser } = useGetCurrentUserQuery()
  const { data: friends, isLoading: isLoadingFriends, refetch: refetchFriends } = useGetFriendsQuery({ from: user?.id || "" })
  const { data: students, isLoading: isLoadingStudents } = useGetAllStudentsQuery()
  const [putFriends, { isLoading: isLoadingP, isSuccess: isSuccessP, isError: isErrorP }] = usePutFriendsMutation()
  const [deleteFriends, { isLoading: isLoadingD, isSuccess: isSuccessD, isError: isErrorD }] = useDeleteFriendsMutation()

  const ref = useRef<{ reset: () => void }>(null)
  const [uids, setUids] = useState<string[]>([])
  const [selected, setSelected] = useState<Set<Student & User & UserApplied>>(new Set())

  const friendIds = new Set<string>((friends || []).map(student => student.id))
  if (user) friendIds.add(user.id)

  useEffect(() => {
    if (isLoadingCurrentUser) return
    if (user === undefined || isErrorCurrentUser) {
      navigate('/')
    }
  }, [user])

  useEffect(() => {
    refetchCurrentUser()
    refetchFriends()
  }, [])

  useEffect(() => {
    if (!isLoadingP) {
      refetchFriends()
    }

    if (!isLoadingP && isSuccessP) toast({
      title: '친구 추가 완료',
      description: '추가되었습니다!',
      status: 'success',
      duration: 9000,
      isClosable: true
    })

    if (!isLoadingP && isErrorP) toast({
      title: '친구 추가 실패',
      description: '친구 추가 중에 오류가 발생하였습니다.',
      status: 'error',
      duration: 9000,
      isClosable: true
    })
  }, [isLoadingP])

  useEffect(() => {
    if (!isLoadingD) {
      refetchFriends()
    }

    if (!isLoadingD && isSuccessD) toast({
      title: '친구 삭제 완료',
      description: '삭제되었습니다.',
      status: 'success',
      duration: 9000,
      isClosable: true
    })

    if (!isLoadingD && isErrorD) toast({
      title: '친구 삭제 실패',
      description: '친구 삭제 중에 오류가 발생하였습니다.',
      status: 'error',
      duration: 9000,
      isClosable: true
    })
  }, [isLoadingD])

  return (
    <div>
      <Navbar active="friend" />
      <Container maxW='container.lg' px={4} py={4}>
        <VStack spacing={7} align="normal">
          <VStack spacing={3} align="normal">
            <chakra.h2 fontWeight="bold" fontSize="lg">
              친구 추가
            </chakra.h2>
            <Skeleton isLoaded={!isLoadingCurrentUser && !isLoadingFriends && !isLoadingStudents}>
              <StudentDropdown
                students={(students || []).filter(student => !friendIds.has(student.id))}
                selected={uids} setSelected={setUids}
                ref={ref}
              />
            </Skeleton>
            <Flex>
              <Spacer />
              <HStack spacing={3}>
                <Button
                  variant="solid" size="sm"
                  onClick={() => {
                    ref.current?.reset()
                  }}
                >
                  취소
                </Button>
                <Button
                  variant="solid" colorScheme="purple" size="sm"
                  disabled={isLoadingP || isLoadingD || uids.length === 0}
                  onClick={() => {
                    putFriends(uids)
                    ref.current?.reset()
                  }}
                >
                  추가
                </Button>
              </HStack>
            </Flex>
          </VStack>
          <VStack spacing={3} align="normal">
            <chakra.h2 fontWeight="bold" fontSize="lg">
              친구 목록
            </chakra.h2>
            <Skeleton isLoaded={!isLoadingCurrentUser && !isLoadingFriends}>
              <StudentView
                students={removeUndefined(friends || [])}
                select={{ selected, setSelected }}
              />
            </Skeleton>
            <Flex>
              <Spacer />
              <HStack spacing={3}>
                <Button
                  variant="solid" size="sm"
                  onClick={() => {
                    setSelected(new Set())
                  }}
                >
                  취소
                </Button>
                <Button
                  variant="solid" colorScheme="red" size="sm"
                  disabled={isLoadingP || isLoadingD || selected.size === 0}
                  onClick={() => {
                    deleteFriends([...selected.values()].map(student => student.id))
                    setSelected(new Set())
                  }}
                >
                  삭제
                </Button>
              </HStack>
            </Flex>
          </VStack>

        </VStack>
      </Container>
    </div>
  )
}