import { Button } from '@chakra-ui/react'
import React from 'react'
import jsPDF from 'jspdf'
import autoTable, { CellHookData } from 'jspdf-autotable'
import { DateTime } from 'luxon'
import { composeAltCode, setFont } from '../lib/helper'
import { useGetAllStudentsQuery, useGetPlacesQuery } from '../redux/reducer/api'

function initialize3DArray(a: number, b: number, c: number): string[][][] {
  const ret: string[][][] = []

  for (let i = 0; i < a; i++) {
    ret.push([])
    for (let j = 0; j < b; j++) {
      ret[i].push([])
      for (let k = 0; k < c; k++) {
        ret[i][j].push('')
      }
    }
  }

  return ret
}

function initialize4DArray(a: number, b: number, c: number, d: number): string[][][][] {
  const ret: string[][][][] = []

  for (let i = 0; i < a; i++) {
    ret.push(initialize3DArray(b, c, d))
  }

  return ret
}

function unFlatArray<T>(arr: T[], a: number, b: number, def: T): T[][] {
  const ret: T[][] = [[]]

  arr.forEach(elem => {
    if (ret[ret.length - 1].length === b) ret.push([])
    ret[ret.length - 1].push(elem)
  })

  while (!(ret.length == a && ret[ret.length - 1].length == b)) {
    if (ret[ret.length - 1].length === b) ret.push([])
    ret[ret.length - 1].push(def)
  }

  return ret
}

export const PDFButton: React.FC = () => {
  const { data: students_raw, isFetching: isFetchingStudents } = useGetAllStudentsQuery()
  const { data: places, isFetching: isFetchingPlaces } = useGetPlacesQuery()

  const generateEmptyCell = (row: number, col: number, minCellHeight: number) => {
    const ret = []

    for (let i = 0; i < row; i++) {
      const inner = []
      for (let j = 0; j < col; j++) {
        inner.push({
          content: '',
          minCellHeight
        })
      }
      ret.push(inner)
    }

    return ret
  }

  const generatePDF = () => {
    const dt = DateTime.now()

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const doc = new jsPDF(orientation, unit, size)
    setFont(doc)

    const outerOption1 = {
      theme: 'grid' as const,
      startY: 70,
      styles: {
        font: 'PretendardVariable',
        fontSize: 9,
        minCellHeight: 323,
        lineWidth: 0,
      },
      body: generateEmptyCell(2, 4, 500)
    }

    const outerOption2 = {
      theme: 'grid' as const,
      startY: 70,
      styles: {
        font: 'PretendardVariable',
        fontSize: 9,
        minCellHeight: 646,
        lineWidth: 0,
      },
      body: generateEmptyCell(1, 4, 500)
    }

    const innerOption = (data: CellHookData) => ({
      theme: 'grid' as const,
      startY: data.cell.y + 3,
      margin: { left: data.cell.x + 3 },
      tableWidth: data.cell.width - 6,
      styles: {
        font: 'PretendardVariable',
        fontSize: 10.5,
        cellPadding: 1.5,
        halign: 'center' as const,
        valign: 'middle' as const
      }
    })

    console.log("asdf", students_raw, places)

    if (students_raw === undefined || places === undefined) return
    const students = students_raw.slice().sort((l, r) => parseInt(composeAltCode(l)) - parseInt(composeAltCode(r)))

    console.log("zsec")

    const studentWiseData = initialize4DArray(3, 8, 20, 4)

    students.forEach(({ name, grade, class_, number_, applied, default: default_, absent }) => {
      studentWiseData[grade - 1][class_ - 1][number_ - 1][0] = `${number_}`
      studentWiseData[grade - 1][class_ - 1][number_ - 1][1] = name

      if (absent) {
        studentWiseData[grade - 1][class_ - 1][number_ - 1][2] = '퇴사'
        studentWiseData[grade - 1][class_ - 1][number_ - 1][3] = '퇴사'
        return
      }

      const place1 = applied[1] || default_[1]
      const place2 = applied[2] || default_[2]
      if (place1 === undefined || place2 === undefined) return
      studentWiseData[grade - 1][class_ - 1][number_ - 1][2] = places.find(place => place.name === place1)?.shortname || ""
      studentWiseData[grade - 1][class_ - 1][number_ - 1][3] = places.find(place => place.name === place2)?.shortname || ""
    })

    doc.setFontSize(18)

    for (let grade = 1; grade <= 3; grade++) {
      if (grade !== 1) doc.addPage()

      doc.text(`${grade}학년 종합출석부`, 45, 60)
      autoTable(doc, {
        ...outerOption1,
        didDrawCell: function (data) {
          if (data.row.section === 'body') {
            autoTable(doc, {
              ...innerOption(data),
              head: [["", `${data.column.index + data.row.index * 4 + 1}반`, "", ""]],
              body: studentWiseData[grade - 1][data.column.index + data.row.index * 4]
            })
          }
        },
      })
    }

    const checksheetData = initialize4DArray(4, 8, 20, 4)
    const area2Data: string[][][] = [[], [], []]

    students.forEach(({ name, grade, class_, number_, absent, roles }) => {
      let target = 0
      if (roles.includes('area1')) {
        if (grade === 1) target = 0
        else target = 1
      }
      if (roles.includes('area2'))
        target = 4
      if (roles.includes('area3')) {
        if (grade === 2) target = 2
        else target = 3
      }

      if (target !== 4) {
        checksheetData[target][class_ - 1][number_ - 1][0] = name
        if (absent) {
          checksheetData[target][class_ - 1][number_ - 1][2] = '퇴사'
          checksheetData[target][class_ - 1][number_ - 1][3] = '퇴사'
        }
      }
      else {
        area2Data[grade - 1].push(absent ? [name, "", "퇴사", "퇴사"] : [name, "", "", ""])
      }
    })

    const titles = ['구관 1학년', '구관 2학년', '신관 2학년', '신관 3학년']
    for (let i = 0; i < 4; i++) {
      doc.addPage()

      doc.text(`${titles[i]} 점호출석부`, 45, 60)
      autoTable(doc, {
        ...outerOption1,
        didDrawCell: function (data) {
          if (data.row.section === 'body') {
            autoTable(doc, {
              ...innerOption(data),
              head: [[`${data.column.index + data.row.index * 4}반`, "기타", "저녁", "아침"]],
              body: checksheetData[i][data.column.index + data.row.index * 4]
            })
          }
        },
      })
    }

    const emptyrow = ["", "", "", ""]
    const area2ViewData = unFlatArray([...area2Data[0], emptyrow, ...area2Data[1], emptyrow, ...area2Data[2]], 4, 40, emptyrow)

    doc.addPage()
    doc.text('여자 기숙사 점호출석부', 45, 60)
    autoTable(doc, {
      ...outerOption2,
      didDrawCell: function (data) {
        if (data.row.section === 'body') {
          autoTable(doc, {
            ...innerOption(data),
            head: [["", "기타", "저녁", "아침"]],
            body: area2ViewData[data.column.index]
          })
        }
      }
    })

    const buildings = new Map<string, string[]>()
    const placeData1 = new Map<string, string[][]>()
    const placeData2 = new Map<string, string[][]>()

    places.forEach(({ name, position }) => {
      const found = buildings.get(position.building)
      if (found === undefined)
        buildings.set(position.building, [name])
      else
        buildings.set(position.building, [...found, name])

      placeData1.set(name, [])
      placeData2.set(name, [])
    })

    students.forEach(student => {
      const { name, absent, applied, default: default_ } = student
      if (absent) return
      const place1 = applied[1] || default_[1]
      const place2 = applied[2] || default_[2]
      if (place1 === undefined || place2 === undefined) return

      const found1 = placeData1.get(place1)
      const found2 = placeData2.get(place2)
      if (found1 === undefined || found2 === undefined) return

      placeData1.set(place1, [...found1, [composeAltCode(student), name, places.find(place => place.name === place1)?.shortname || ""]])
      placeData2.set(place2, [...found2, [composeAltCode(student), name, places.find(place => place.name === place2)?.shortname || ""]])
    })

    for (const [building, placesin] of buildings.entries()) {
      const emptyrow = ['', '', '']
      const flatData1: string[][] = []
      const flatData2: string[][] = []

      placesin.forEach(place => {
        const found1 = placeData1.get(place)
        const found2 = placeData2.get(place)
        if (found1 === undefined || found2 === undefined) return
        flatData1.push(...found1, emptyrow)
        flatData2.push(...found2, emptyrow)
      })

      {
        const pages = Math.floor((flatData1.length - 1) / 160) + 1
        const pageDivided = unFlatArray(flatData1, pages, 160, emptyrow)
        for (let page = 1; page <= pages; page++) {
          doc.addPage()
          doc.text(`${building} 1교시 실별출석부 (${page}/${pages})`, 45, 60)
          autoTable(doc, {
            ...outerOption2,
            didDrawCell: function (data) {
              if (data.row.section === 'body') {
                autoTable(doc, {
                  ...innerOption(data),
                  head: [["학번", "이름", "장소"]],
                  body: unFlatArray(pageDivided[page - 1], 4, 40, emptyrow)[data.column.index]
                })
              }
            }
          })
        }
      }

      {
        const pages = Math.floor((flatData2.length - 1) / 160) + 1
        const pageDivided = unFlatArray(flatData2, pages, 160, emptyrow)
        for (let page = 1; page <= pages; page++) {
          doc.addPage()
          doc.text(`${building} 2교시 실별출석부 (${page}/${pages})`, 45, 60)
          autoTable(doc, {
            ...outerOption2,
            didDrawCell: function (data) {
              if (data.row.section === 'body') {
                autoTable(doc, {
                  ...innerOption(data),
                  head: [["학번", "이름", "장소"]],
                  body: unFlatArray(pageDivided[page - 1], 4, 40, emptyrow)[data.column.index]
                })
              }
            }
          })
        }
      }
    }

    doc.save(`seat-${dt.toFormat('yyyy-MM-dd')}.pdf`)
  }

  return (
    <Button
      colorScheme="purple" variant="outline"
      isLoading={isFetchingPlaces || isFetchingStudents}
      onClick={generatePDF}
    >
      PDF 파일로 내보내기
    </Button>
  )
}
