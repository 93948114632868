import { Button, Input, Td, Tr, useColorModeValue, Wrap } from '@chakra-ui/react'
import React, { useState } from 'react'
import { removeUndefined } from '../../lib/helper'
import { Place } from '../../lib/types'
import { useDeletePlacesMutation, usePatchPlacesMutation, usePutPlacesMutation } from '../../redux/reducer/api'

type Props = {
  place: Place
  period: 1 | 2
  refetch: () => void
}

type EditingItemProps = {
  place: Place
  period: 1 | 2
  setOnEdit: (onEdit: boolean) => void
  refetch: () => void
}

const EditingItem: React.FC<EditingItemProps> = (props: EditingItemProps) => {
  const { place, period, setOnEdit, refetch: refetchPlaces } = props
  const possible = true
  const [permission, setPermission] = useState<boolean>(removeUndefined(place.permission[period].kinds || []).includes('student') ? false : true)
  const [capacity, setCapacity] = useState<string>(String(place.capacity[period]))
  const [Save] = usePatchPlacesMutation()
  const [Delete] = useDeletePlacesMutation()
  const [shortname, setShortName] = useState<string>(place.shortname)
  return (
    <Tr
      key={place.name}
      transitionProperty="common" transitionDuration="normal"
      _hover={possible ? { background: useColorModeValue("gray.200", "whiteAlpha.200") } : undefined}
      cursor={possible ? "pointer" : "not-allowed"}
    >
      <Td>
        {place.position.floor}
      </Td>
      <Td>
        {place.name}
      </Td>
      <Td>
        <Input size="sm" value={shortname} onChange={(e) => setShortName(e.target.value)} />
      </Td>
      <Td>
        <Button size="sm" onClick={() => { setPermission(!permission) }}>{permission ? '선생님' : '학생'}</Button>
      </Td>
      <Td isNumeric>{place.current[period]}</Td>
      <Td isNumeric>
        <Input size="sm" value={capacity} onChange={(e) => setCapacity(e.target.value)} />
      </Td>
      <Td>
        <Wrap spacing={3}>
          <Button
            size="sm" colorScheme="green"
            onClick={async () => {
              setOnEdit(false)
              await Save({
                name: place.name,
                shortname: shortname,
                current: place.current,
                capacity: {
                  1: period === 1 ? Number(capacity) : place.capacity[1],
                  2: period === 2 ? Number(capacity) : place.capacity[2]
                },
                position: place.position,
                permission: {
                  1: period === 1 ? (permission ? { kinds: ["teacher"] } : { kinds: ["teacher", "student"] }) : place.permission[1],
                  2: period === 2 ? (permission ? { kinds: ["teacher"] } : { kinds: ["teacher", "student"] }) : place.permission[2]
                },
              })
              refetchPlaces()
            }}
          >
            저장
          </Button>
          <Button
            size="sm" colorScheme="red"
            onClick={async () => {
              setOnEdit(false)
              await Delete({ name: place.name })
              refetchPlaces()
            }}
          >
            삭제
          </Button>
        </Wrap>
      </Td>
    </Tr>
  )
}

export const PlaceItem: React.FC<Props> = (props: Props) => {
  const { place, period, refetch } = props
  const possible = true
  const [onEdit, setOnEdit] = useState<boolean>(false)

  if (onEdit) return <EditingItem place={place} period={period} setOnEdit={setOnEdit} refetch={refetch} />

  return (
    <Tr
      key={place.name}
      transitionProperty="common" transitionDuration="normal"
      _hover={possible ? { background: useColorModeValue("gray.200", "whiteAlpha.200") } : undefined}
      cursor={possible ? "pointer" : "not-allowed"}
    >
      <Td>{place.position.floor}</Td>
      <Td>
        {place.name}
      </Td>
      <Td>
        {place.shortname}
      </Td>
      <Td>
        {removeUndefined(place.permission[period].kinds || []).includes('student') ? '학생' : '선생님'}
      </Td>
      <Td isNumeric>{place.current[period]}</Td>
      <Td isNumeric>{place.capacity[period]}</Td>
      <Td>
        <Button size="sm" onClick={() => setOnEdit(true)}
        >수정</Button>
      </Td>
    </Tr>
  )
}

type AddPlaceItemProps = {
  period: 1 | 2,
  building: string,
  refetch: () => void
}

export const AddPlaceItem: React.FC<AddPlaceItemProps> = (props: AddPlaceItemProps) => {
  const { period, building, refetch: refetchPlaces } = props
  const possible = true
  const [onEdit, setOnEdit] = useState<boolean>(false)
  const [name, setName] = useState<string>("")
  const [shortname, setShortName] = useState<string>("")
  const [floor, setFloor] = useState<string>("")
  const [permission, setPermission] = useState<boolean>(true)
  const [capacity, setCapacity] = useState<string>("10")
  const [Save] = usePutPlacesMutation()

  if (!onEdit) {
    return (
      <Tr
        key={"장소추가"}
        transitionProperty="common" transitionDuration="normal"
        _hover={possible ? { background: useColorModeValue("gray.200", "whiteAlpha.200") } : undefined}
        cursor={possible ? "pointer" : "not-allowed"}
      >
        <Td></Td>
        <Td></Td>
        <Td></Td>
        <Td></Td>
        <Td></Td>
        <Td>
          <Button onClick={() => setOnEdit(true)}>+</Button>
        </Td>
      </Tr>
    )
  }

  return (
    <Tr
      key={"장소추가"}
      transitionProperty="common" transitionDuration="normal"
      _hover={possible ? { background: useColorModeValue("gray.200", "whiteAlpha.200") } : undefined}
      cursor={possible ? "pointer" : "not-allowed"}
    >
      <Td>
        <Input size="sm" value={floor} onChange={(e) => setFloor(e.target.value)} />
      </Td>
      <Td>
        <Input size="sm" value={name} onChange={(e) => setName(e.target.value)} />
      </Td>
      <Td>
        <Input size="sm" value={shortname} onChange={(e) => setShortName(e.target.value)} />
      </Td>
      <Td>
        <Button size="sm" onClick={() => { setPermission(!permission) }}>{permission ? '선생님' : '학생'}</Button>
      </Td>
      <Td isNumeric>{0}</Td>
      <Td isNumeric>
        <Input size="sm" value={capacity} onChange={(e) => setCapacity(e.target.value)} />
      </Td>
      <Td>
        <Button
          size="sm"
          colorScheme="green"
          onClick={() => {
            Save({
              name: name,
              shortname: shortname,
              current: { 1: 0, 2: 0 },
              capacity: {
                1: period === 1 ? Number(capacity) : 0,
                2: period === 2 ? Number(capacity) : 0
              },
              position: {
                building: building,
                floor: floor
              },
              permission: {
                1: period === 1 ? (permission ? { kinds: ["teacher"] } : { kinds: ["teacher", "student"] }) : { kinds: ["teacher", "student"] },
                2: period === 2 ? (permission ? { kinds: ["teacher"] } : { kinds: ["teacher", "student"] }) : { kinds: ["teacher", "student"] }
              },
            })
            refetchPlaces()
            setOnEdit(false)
            setName("")
            setFloor("")
          }}
        >
          저장
        </Button>
      </Td>
    </Tr>
  )
}