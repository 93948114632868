import { BasePermission, Event, Place, Student, User, UserApplied } from "./types";
import font from "./font"
import jsPDF from "jspdf";

export function setFont(doc: jsPDF): void {
  doc.addFileToVFS('PretendardVariable-normal.ttf', font)
  doc.addFont('PretendardVariable-normal.ttf', 'PretendardVariable', 'normal')
  doc.setFont('PretendardVariable')
}

export function isNotUndefined<T>(x: T | undefined): x is T {
  return !!x
}

export function removeUndefined<T>(arr?: (T | undefined)[]): T[] {
  return arr ? arr.filter(isNotUndefined) : []
}

export function isStudent(user: User & UserApplied): user is User & Student & UserApplied {
  return user.kind === 'student'
}

export function composeAltCode(student: Student): string {
  const { grade, class_, number_ } = student
  return `${grade}${class_}${number_.toString().padStart(2, '0')}`
}

export function stringComparator(lhs?: string, rhs?: string): number {
  if ((lhs || "") > (rhs || "")) return 1
  if ((lhs || "") < (rhs || "")) return -1
  return 0
}

export function parseDate(v: string | Date): Date {
  if (v instanceof Date) return v
  return new Date(Date.parse(v))
}

export function sanitizeEvent(event: Event): Event {
  return { ...event, start: parseDate(event.start), end: parseDate(event.end) }
}

export function intersect<T>(a: T[], b: T[]): T[] {
  const setB = new Set(b);
  return [...new Set(a)].filter(x => setB.has(x));
}

export function isSatisfied(user: User, permission: BasePermission): boolean {
  if (permission.kinds && !permission.kinds.includes(user.kind)) return false
  if (permission.roles && !intersect(permission.roles, user.roles)) return false
  return true
}

export function isPossible(users: (User & UserApplied)[], place: Place, period: 1 | 2): boolean {
  if (!users.every((user) => isSatisfied(user, place.permission[period]))) return false

  const overlap = users.filter(user => (user.applied[period] || user.default[period]) === place.name).length
  return place.current[period] + users.length - overlap <= place.capacity[period]
}

export function GetAreaByUser(user: User) : ("area1" | "area2" | "area3") {
  if (user.roles.includes("area1")) return "area1"
  if (user.roles.includes("area2")) return "area2"
  if (user.roles.includes("area3")) return "area3"
  return "area1"
}

export function AreaNameToKorean(name: ("area1" | "area2" | "area3") ) : ("구관" | "여사" | "신관") {
  if (name==="area1") return "구관"
  if (name==="area2") return "여사"
  return "신관"
}