import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  chakra, Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr, useColorModeValue, VStack, Wrap
} from "@chakra-ui/react";
import React, { useState } from "react";
import { isPossible } from "../lib/helper";
import { Place, User, UserApplied } from "../lib/types";
import { useGetPlacesQuery } from "../redux/reducer/api";

type Props = {
  users: (User & UserApplied)[],
  place1?: string,
  setPlace1: React.Dispatch<React.SetStateAction<string | undefined>>,
  place2?: string,
  setPlace2: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const PlaceSelector: React.FC<Props> = (props: Props) => {
  const { data: places, isLoading } = useGetPlacesQuery()
  const [period, setPeriod] = useState<1 | 2>(1)
  const rowcolor = useColorModeValue("gray.200", "whiteAlpha.200")

  const { users, place1, setPlace1, place2, setPlace2 } = props
  const placeMap = new Map<string, Place[]>()
  

  places?.forEach(place => {
    placeMap.set(place.position.building, [...(placeMap.get(place.position.building) || []), place])
  })

  return (
    <Skeleton isLoaded={!isLoading}>
      <VStack spacing={3} align='normal'>
        <Wrap spacing={3}>
          <Button
            variant="solid" size="sm"
            colorScheme={period === 1 ? "purple" : undefined}
            onClick={() => setPeriod(1)}
          >
            1교시: {place1 || "(선택되지 않음)"}
          </Button>
          <Button
            variant="solid" size="sm"
            colorScheme={period === 2 ? "purple" : undefined}
            onClick={() => setPeriod(2)}
          >
            2교시: {place2 || "(선택되지 않음)"}
          </Button>
        </Wrap>

        <Accordion allowToggle allowMultiple>
          <AccordionItem></AccordionItem>
          {[...placeMap.entries()].map(([building, placesin]) => (
            <AccordionItem key={building}>
              <chakra.h2>
                <AccordionButton>
                  <Box flex='1' textAlign='left'>
                    {building}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </chakra.h2>
              <AccordionPanel pb={3}>
                <Table variant="simple" borderWidth="1px">
                  <Thead>
                    <Tr>
                      <Th>이름</Th>
                      <Th isNumeric>인원</Th>
                      <Th isNumeric>정원</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {placesin.map(place => {
                      const possible = isPossible(users, place, period)
                      return (
                        <Tr
                          key={place.name}
                          transitionProperty="common" transitionDuration="normal"
                          _hover={possible ? { background: rowcolor } : undefined}
                          cursor={possible ? "pointer" : "not-allowed"}
                          onClick={() => {
                            if (possible) (period === 1 ? setPlace1 : setPlace2)(place.name)
                          }}
                        >
                          <Td>
                            {place.name}
                            {possible
                              ? <Badge variant="solid" ml={1} colorScheme="green">가능</Badge>
                              : <Badge variant="solid" ml={1} colorScheme="red">불가능</Badge>
                            }
                          </Td>
                          <Td isNumeric>{place.current[period]}</Td>
                          <Td isNumeric>{place.capacity[period]}</Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </VStack>
    </Skeleton>
  )
}
