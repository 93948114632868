import { Button, chakra, Container, Flex, HStack, Radio, RadioGroup, Skeleton, Spacer, Stack, VStack } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Navbar } from '../components/Navbar'
import { StudentDropdown } from '../components/StudentDropdown'
import { StudentViewByArea } from '../components/StudentViewByArea'
import { AreaNameToKorean, GetAreaByUser, removeUndefined } from '../lib/helper'
import { Student, User, UserApplied } from '../lib/types'
import { useGetAllStudentsQuery, useGetCurrentUserQuery, usePostRoleMutation } from '../redux/reducer/api'

export const ManageArea: React.FC = () => {
  const navigate = useNavigate()
  const { data: user, isLoading: isLoadingCurrentUser, refetch: refetchCurrentUser, isError: isErrorCurrentUser } = useGetCurrentUserQuery()
  const { data: students, isLoading: isLoadingStudents, refetch: refetchAllStudents } = useGetAllStudentsQuery()

  const ref = useRef<{ reset: () => void }>(null)
  const [uids, setUids] = useState<string[]>([])
  const [selected, setSelected] = useState<Set<Student & User & UserApplied>>(new Set())

  const [area, setArea] = useState<"area1" | "area2" | "area3">("area1")

  const [postRole] = usePostRoleMutation()

  useEffect(() => {
    if (isLoadingCurrentUser) return
    if (user === undefined || !user.roles.includes('admin') || isErrorCurrentUser) {
      navigate('/')
    }
  }, [user])

  useEffect(() => {
    refetchCurrentUser()
  }, [])

  return (
    <div>
      <Navbar active="managearea" />
      <Container maxW='container.lg' px={4} py={4}>
        <VStack spacing={7} align="normal">
          <VStack spacing={3} align="normal">
            <chakra.h2 fontWeight="bold" fontSize="lg">
              구역 선택
            </chakra.h2>
            <RadioGroup onChange={(e) => { setArea(e as ("area1" | "area2" | "area3")) }} value={area}>
              <Stack direction="row">
                <Radio value="area1">구관</Radio>
                <Radio value="area2">여사</Radio>
                <Radio value="area3">신관</Radio>
              </Stack>
            </RadioGroup>

            <chakra.h2 fontWeight="bold" fontSize="lg">
              학생 선택
            </chakra.h2>
            <Skeleton isLoaded={!isLoadingCurrentUser && !isLoadingStudents}>
              <StudentDropdown
                students={(students || [])}
                selected={uids} setSelected={setUids}
                ref={ref}
                studentInfo={(s) => AreaNameToKorean(GetAreaByUser(s))}
              />
            </Skeleton>
            <Flex>
              <Spacer />
              <HStack spacing={3}>
                <Button
                  variant="solid" size="sm"
                  onClick={() => {
                    ref.current?.reset()
                  }}
                >
                  취소
                </Button>
                <Button
                  variant="solid" colorScheme="purple" size="sm"
                  disabled={uids.length === 0}
                  onClick={async () => {
                    //졸려서 대충짬 ㅈㅅ
                    await postRole({
                      id: "area1",
                      add: [],
                      del: uids
                    })
                    await postRole({
                      id: "area2",
                      add: [],
                      del: uids
                    })
                    await postRole({
                      id: "area3",
                      add: [],
                      del: uids
                    })
                    await postRole({
                      id: area,
                      add: uids,
                      del: []
                    })
                    refetchAllStudents()
                    ref.current?.reset()
                  }}
                >
                  설정
                </Button>
              </HStack>
            </Flex>
          </VStack>


          <VStack spacing={3} align="normal">
            <chakra.h2 fontWeight="bold" fontSize="lg">
              학생 목록
            </chakra.h2>
            <Skeleton isLoaded={!isLoadingCurrentUser && !isLoadingStudents}>
              <StudentViewByArea
                students={removeUndefined(students || [])}
                select={{ selected, setSelected }}
              />
            </Skeleton>
          </VStack>

        </VStack>
      </Container>
    </div>
  )
}