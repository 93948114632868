import {
  Box,
  Button,
  chakra, CloseButton, Container, Flex,
  HStack,
  IconButton,
  Slide,
  useColorModeValue,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import React from "react";
import { AiOutlineMenu } from 'react-icons/ai'
import { Link } from "react-router-dom";
import { useGetCurrentUserQuery } from "../redux/reducer/api";
import { ColorModeToggler } from "./ColorModeToggler";
import { GoogleButton } from "./GoogleButton";

type Props = {
  active: "home" | "status" | "apply" | "request" | "friend" | "place" | "managerequest" | "managearea"
}

export const Navbar: React.FC<Props> = (props: Props) => {
  const bg = useColorModeValue("white", "gray.800")
  const { isOpen, onToggle } = useDisclosure()
  const { data: user, isError } = useGetCurrentUserQuery()

  return (
    <chakra.header
      bg={bg}
      w="full"
      shadow='md'
    >
      <Container maxW='container.lg' px={4} py={4}>
        <Flex alignItems="center" justifyContent="space-between" mx="auto">
          <Flex>
            <chakra.h1
              fontSize="xl" fontWeight="bold"
            >
              Seat
            </chakra.h1>
          </Flex>
          <HStack display="flex" alignItems="center" spacing={3}>
            <HStack
              spacing={1}
              mr={1}
              color="brand.500"
              display={{ base: "none", md: "inline-flex" }}
            >
              {user && !isError &&
                <>
                  <Link to='/'>
                    <Button variant="ghost" colorScheme={props.active === "home" ? "purple" : undefined}>
                      홈
                    </Button>
                  </Link>
                  <Link to='/status'>
                    <Button variant="ghost" colorScheme={props.active === "status" ? "purple" : undefined}>
                      전체 현황
                    </Button>
                  </Link>
                  <Link to='/apply'>
                    <Button variant="ghost" colorScheme={props.active === "apply" ? "purple" : undefined}>
                      이석 신청
                    </Button>
                  </Link>
                  <Link to='/request'>
                    <Button variant="ghost" colorScheme={props.active === "request" ? "purple" : undefined}>
                      퇴사 및 외출
                    </Button>
                  </Link>
                  <Link to='/friend'>
                    <Button variant="ghost" colorScheme={props.active === "friend" ? "purple" : undefined}>
                      친구
                    </Button>
                  </Link>
                </>
              }

              {
                (user && user.roles.includes('admin')) &&
                <>
                  <Link to='/place'>
                    <Button variant="ghost" colorScheme={props.active === "place" ? "purple" : undefined}>
                      장소 관리
                    </Button>
                  </Link>
                  <Link to='/request/manage'>
                    <Button variant="ghost" colorScheme={props.active === "managerequest" ? "purple" : undefined}>
                      신청 관리
                    </Button>
                  </Link>
                  <Link to='/area/manage'>
                    <Button variant="ghost" colorScheme={props.active === "managearea" ? "purple" : undefined}>
                      영역 관리
                    </Button>
                  </Link>
                </>
              }
            </HStack>
            <ColorModeToggler />
            <GoogleButton />
            <Box display={{ base: "inline-flex", md: "none" }}>
              <IconButton
                display={{ base: "flex", md: "none" }}
                aria-label="Open menu"
                fontSize="20px"
                color={useColorModeValue("gray.800", "inherit")}
                variant="ghost"
                icon={<AiOutlineMenu />}
                onClick={onToggle}
              />

              <Slide direction="top" in={isOpen}>
                <VStack
                  flexDirection="column"
                  p={2} pb={4} m={2}
                  bg={bg}
                  spacing={3}
                  rounded="sm"
                  shadow="sm"
                >
                  <CloseButton
                    aria-label="Close menu"
                    onClick={onToggle}
                  />

                  <Link to='/'>
                    <Button w="full" variant="ghost" colorScheme={props.active === "home" ? "purple" : undefined}>
                      홈
                    </Button>
                  </Link>
                  <Link to='/status'>
                    <Button w="full" variant="ghost" colorScheme={props.active === "status" ? "purple" : undefined}>
                      전체 현황
                    </Button>
                  </Link>
                  <Link to='/apply'>
                    <Button w="full" variant="ghost" colorScheme={props.active === "apply" ? "purple" : undefined}>
                      이석 신청
                    </Button>
                  </Link>
                  <Link to='/request'>
                    <Button w="full" variant="ghost" colorScheme={props.active === "request" ? "purple" : undefined}>
                      퇴사 및 외출
                    </Button>
                  </Link>
                  <Link to='/friend'>
                    <Button w="full" variant="ghost" colorScheme={props.active === "friend" ? "purple" : undefined}>
                      친구
                    </Button>
                  </Link>
                  <Link to='/place'>
                    <Button variant="ghost" colorScheme={props.active === "place" ? "purple" : undefined}>
                      장소 관리
                    </Button>
                  </Link>
                  <Link to='/request/manage'>
                    <Button variant="ghost" colorScheme={props.active === "managerequest" ? "purple" : undefined}>
                      신청 관리
                    </Button>
                  </Link>
                  <Link to='/area/manage'>
                    <Button variant="ghost" colorScheme={props.active === "managearea" ? "purple" : undefined}>
                      영역 관리
                    </Button>
                  </Link>
                </VStack>
              </Slide>
            </Box>
          </HStack>
        </Flex>
      </Container>
    </chakra.header>
  );
}
