import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons'
import { Flex, IconButton, Skeleton, Spacer, Text, VStack } from '@chakra-ui/react'
import Calendar from '@toast-ui/react-calendar'
import { DateTime } from 'luxon'
import React, { useEffect, useRef, useState } from 'react'
import { Event } from '../lib/types'
import { useGetEventsQuery, useGetRequestsQuery } from '../redux/reducer/api'

function getTitle(event: Event & { pending: boolean }): string {
  const startDt = DateTime.fromJSDate(event.start)
  const endDt = DateTime.fromJSDate(event.end)

  if (event.type === "exile") {
    return `퇴사`
  } else {
    return `${startDt.toFormat("HH:mm")} ~ ${endDt.toFormat("HH:mm")}`
  }
}

export const EventCalendar: React.FC = () => {
  // eslint-disable-next-line
  const ref = useRef() as any
  const { data: events, isLoading: isLoadingEvent } = useGetEventsQuery()
  const { data: requests, isLoading: isLoadingRequests } = useGetRequestsQuery({ status: "pending" })
  const [datetime, setDateTime] = useState<DateTime>(DateTime.now())

  const eventsDisplay = [
    ...(requests?.map(request => ({ ...request.event, pending: true })) || []),
    ...(events?.map(event => ({ ...event, pending: false })) || [])
  ]

  useEffect(() => {
    if (ref.current) {
      setDateTime(DateTime.fromJSDate(ref.current.getInstance().getDate().toDate()))
      console.log(ref.current.getInstance().getSchedule('0', '0'))
    }
  }, [ref])

  return (
    <Skeleton isLoaded={!isLoadingEvent && !isLoadingRequests}>
      <VStack spacing={3} align="normal">
        <Flex>
          <IconButton
            aria-label='prev' variant="ghost" size="sm" icon={<ArrowLeftIcon />}
            onClick={() => {
              ref.current.getInstance().prev()
              setDateTime(DateTime.fromJSDate(ref.current.getInstance().getDate().toDate()))
            }}
          />
          <Spacer />
          <Text my="auto">{datetime.year}년 {datetime.month}월</Text>
          <Spacer />
          <IconButton
            aria-label='next' variant="ghost" size="sm" icon={<ArrowRightIcon />}
            onClick={() => {
              ref.current.getInstance().next()
              setDateTime(DateTime.fromJSDate(ref.current.getInstance().getDate().toDate()))
            }}
          />
        </Flex>
        <Calendar
          ref={ref}
          height='600px' view='month'
          color="gray.800"
          isReadOnly={true}
          scheduleView={['time']}
          calendars={[{
            id: 'exile',
            name: 'exile',
            bgColor: '#B794F4',
            borderColor: '#B794F4'
          }, {
            id: 'picnic',
            name: 'picnic',
            bgColor: '#F687B3',
            borderColor: '#F687B3'
          }, {
            id: 'pending',
            name: 'pending',
            bgColor: '#D69E2E',
            borderColor: '#D69E2E'
          }]}
          schedules={eventsDisplay.map((event, idx) => ({
            id: idx.toString(),
            calendarId: event.pending ? "pending" : event.type,
            title: getTitle(event),
            category: 'time',
            dueDateClass: '',
            start: event.start,
            end: event.end
          }))}
        />
      </VStack>
    </Skeleton>
  )
}