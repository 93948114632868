import { chakra, Checkbox, Container, HStack, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Navbar } from '../components/Navbar'
import { RequestList } from '../components/RequestList'
import { useGetCurrentUserQuery } from '../redux/reducer/api'

export const ManageRequest: React.FC = () => {
  const { data: user, isLoading, isError } = useGetCurrentUserQuery()
  const [showPast, setShowPast] = useState<boolean>(false)
  // eslint-disable-next-line
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoading) return
    if (user === undefined || !user.roles.includes('admin') || isError) {
      navigate('/')
    }
  }, [user])

  return (
    <div>
      <Navbar active="managerequest" />
      <Container maxW='container.lg' px={4} py={4}>
        <VStack spacing={7} align="normal">
          <VStack spacing={3} align="normal">
            <HStack>
              <chakra.h2 fontWeight="medium" fontSize="lg">
                신청 목록
              </chakra.h2>
              <Checkbox isChecked={showPast} onChange={(e) => setShowPast(e.target.checked)}>지난 기록도 보기</Checkbox>
            </HStack>
            <RequestList
              setIsFetching={setIsFetching}
              showpast={showPast}
            />
          </VStack>
        </VStack>
      </Container>
    </div>
  )
}