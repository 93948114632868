import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { AuthProvider } from './components/AuthProvider';
import { Apply } from './pages/Apply';
import { Friend } from './pages/Friend';
import { Home } from './pages/Home';
import { Request } from './pages/Request';
import { Status } from './pages/Status';
import configureStore from './redux/store';

import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Place } from './pages/Place';
import { ManageRequest } from './pages/ManageRequest';
import { ManageArea } from './pages/ManageArea';

const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={store.__persistor!}>
      <ChakraProvider>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='status' element={<Status />} />
              <Route path='apply' element={<Apply />} />
              <Route path='request' element={<Request />} />
              <Route path='friend' element={<Friend />} />
              <Route path='place' element={<Place />} />
              <Route path='request/manage' element={<ManageRequest />} />
              <Route path='area/manage' element={<ManageArea />} />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </ChakraProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
