import {
  Badge, chakra, HStack,
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useClipboard,
  WrapItem
} from "@chakra-ui/react";
import React from "react";
import { useGetCurrentUserQuery } from "../redux/reducer/api";

export const BasicInfo: React.FC = () => {
  const { data, isLoading } = useGetCurrentUserQuery()
  const { hasCopied, onCopy } = useClipboard(data?.id || "")

  return (
    <Skeleton isLoaded={!isLoading}>
      <Table variant="simple" borderWidth="1px" display={{ base: 'none', md: 'table' }}>
        <Thead>
          <Tr>
            <Th>로그인된 계정</Th>
            <Th>역할</Th>
            <Th>1교시</Th>
            <Th>2교시</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>
              <WrapItem>
                <Tooltip
                  hasArrow placement="top" closeOnClick={false}
                  label={hasCopied ? "복사되었어요!" : `ID: ${data?.id || ""} (클릭해서 복사)`}
                >
                  <chakra.span cursor="pointer" onClick={onCopy}>
                    {data?.name} ({data?.code}@sshs.hs.kr)
                  </chakra.span>
                </Tooltip>
              </WrapItem>
            </Td>
            <Td><HStack>{data?.roles.map((role, index) => <Badge variant="solid" key={index}>{role}</Badge>)}</HStack></Td>
            <Td>{data?.applied[1] || data?.default[1]}</Td>
            <Td>{data?.applied[2] || data?.default[2]}</Td>
          </Tr>
        </Tbody>
      </Table>
      <Table variant="simple" borderWidth="1px" display={{ base: 'table', md: 'none' }} mb={3}>
        <Thead>
          <Tr>
            <Th>로그인된 계정</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{data?.name} ({data?.code}@sshs.hs.kr)</Td>
          </Tr>
        </Tbody>
      </Table>
      <Table variant="simple" borderWidth="1px" display={{ base: 'table', md: 'none' }} mb={3}>
        <Thead>
          <Tr>
            <Th>역할</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td><HStack>{data?.roles.map((role, index) => <Badge variant="solid" key={index}>{role}</Badge>)}</HStack></Td>
          </Tr>
        </Tbody>
      </Table>
      <Table variant="simple" borderWidth="1px" display={{ base: 'table', md: 'none' }}>
        <Thead>
          <Tr>
            <Th>1교시</Th>
            <Th>2교시</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{data?.applied[1] || data?.default[1]}</Td>
            <Td>{data?.applied[2] || data?.default[2]}</Td>
          </Tr>
        </Tbody>
      </Table>
    </Skeleton>
  )
}
