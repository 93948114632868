import {
  Alert,
  AlertIcon,
  chakra,
  Container,
  VStack
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { Application } from '../components/Application'
import { BasicInfo } from '../components/BasicInfo'
import { Navbar } from '../components/Navbar'
import { removeUndefined } from '../lib/helper'
import { useGetCurrentUserQuery } from '../redux/reducer/api'

export const Home: React.FC = () => {
  const { data: user, isLoading, refetch, isError } = useGetCurrentUserQuery()
  const users = removeUndefined([user])

  useEffect(() => {
    refetch()
  }, [])

  return (
    <div>
      <Navbar active="home" />
      <Container maxW='container.lg' px={4} py={4}>
        <VStack spacing={7} align="normal">
          {
            user && !isError ?
              <>
                <VStack spacing={3} align="normal">
                  <chakra.h2 fontWeight="bold" fontSize="lg">
                    현재 상태
                  </chakra.h2>
                  <BasicInfo />
                </VStack>
                <VStack spacing={3} align="normal">
                  <chakra.h2 fontWeight="bold" fontSize="lg">
                    빠른 현황 및 신청
                  </chakra.h2>
                  <Application users={users} isFetching={isLoading} onSuccess={refetch} />
                </VStack>
              </> :
              <>
                <Alert status='warning'>
                  <AlertIcon />
                  이석 시스템의 이용을 위해서는 구글 로그인이 필요해요!
                </Alert>
              </>
          }
        </VStack>
      </Container>
    </div>
  )
}