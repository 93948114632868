import { chakra, Container, VStack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'tui-calendar/dist/tui-calendar.css';
import { EventCalendar } from '../components/EventCalendar';
import { Navbar } from '../components/Navbar';
import { RequestForm } from '../components/RequestForm';
import { useGetCurrentUserQuery } from '../redux/reducer/api';


export const Request: React.FC = () => {
  const navigate = useNavigate()
  const { data: user, isLoading, isError } = useGetCurrentUserQuery()

  useEffect(() => {
    if (isLoading) return
    if (user === undefined || isError) {
      navigate('/')
    }
  }, [user])

  return (
    <div>
      <Navbar active="request" />
      <Container maxW='container.lg' px={4} py={4}>
        <VStack spacing={7} align="normal">
          <VStack spacing={3} align="normal">
            <chakra.h2 fontWeight="bold" fontSize="lg">
              확정된 이벤트
            </chakra.h2>
            <EventCalendar />
          </VStack>
          <VStack spacing={3} align="normal">
            <chakra.h2 fontWeight="bold" fontSize="lg">
              퇴사 및 외출 신청
            </chakra.h2>
            <RequestForm />
          </VStack>
        </VStack>
      </Container>
    </div>
  )
}