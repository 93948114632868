import { Select, Table, Tbody, Td, Text, Th, Thead, Tr, useBreakpointValue, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { AreaNameToKorean, composeAltCode, GetAreaByUser, stringComparator } from "../lib/helper";
import { Student, User, UserApplied } from "../lib/types";

type Props = {
  students: (User & Student & UserApplied)[],
  select?: {
    selected: Set<User & Student & UserApplied>,
    setSelected: React.Dispatch<React.SetStateAction<Set<User & Student & UserApplied>>>
  }
}

export const StudentViewByArea: React.FC<Props> = (props: Props) => {
  const [sorter, setSorter] = useState<string>("")
  const size = useBreakpointValue({ base: 'sm', md: 'md' })
  const { students: studentsRaw } = props

  const students = ["area1", "area2", "area3"].includes(sorter) ? [...studentsRaw].filter(student => student.roles.includes(sorter)) :  [...studentsRaw]
  students.sort((l, r) => {
    if (sorter === "name") return stringComparator(l.name, r.name)
    if (sorter === "area") return stringComparator(GetAreaByUser(l), GetAreaByUser(r))
    return stringComparator(composeAltCode(l), composeAltCode(r))
  })

  return (
    <VStack spacing={3}>
      <Select
        variant="flushed" size={size}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setSorter(e.target.value)
        }}
      >
        <option value="altcode" selected>학번순</option>
        <option value="name">이름순</option>
        <option value="area">구역 순</option>
        <option value="area1">구관</option>
        <option value="area2">여사</option>
        <option value="area3">신관</option>
      </Select>
      <Table variant="simple" borderWidth="1px" size={size}>
        <Thead>
          <Tr>
            <Th>학번</Th>
            <Th>이름</Th>
            <Th>구역</Th>
            <Th>방번호</Th>
          </Tr>
        </Thead>
        <Tbody>
          {students.map(student => (
            <Tr key={student.id}>
              <Td>{composeAltCode(student)}</Td>
              <Td>{student.name}</Td>
              <Td><Text isTruncated>{AreaNameToKorean(GetAreaByUser(student))}</Text></Td>
              <Td><Text isTruncated></Text></Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </VStack>
  )
}