import { Button, HStack, Skeleton, Table, Tbody, Td, Th, Thead, Tr, useToast, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { removeUndefined, composeAltCode } from '../lib/helper'
import { useGetAdminRequestsQuery, usePostRequestMutation, useGetAllStudentsQuery } from '../redux/reducer/api'

type Props = {
  showpast?: boolean
  setIsFetching: (isFetching: boolean) => void
}

function BooleantoNumber(bool: boolean): number {
  if (bool) { return -1 }
  else { return 1 }
}

function DateToString(date: Date): string {
  const str = date.toString()
  // eslint-disable-next-line
  const [y, m, ect] = str.split('-')
  const [d, ti] = ect.split('T')
  return m + '/' + d + '  ' + ti.split(':')[0] + ':' + ti.split(':')[1]
}

function StatusToString(sat: string): string {
  if (sat === 'pending') { return '대기중' }
  if (sat === 'rejected') { return '거절' }
  if (sat === 'approved') { return '승인' }
  return 'error'
}

export const RequestList: React.FC<Props> = (props: Props) => {
  const { data, isFetching: isFetchingRequest, refetch } = useGetAdminRequestsQuery()
  const { data: students } = useGetAllStudentsQuery()
  const [requestpost, { isLoading, isSuccess, isError }] = usePostRequestMutation()
  const toast = useToast()

  useEffect(() => {
    if (!isLoading) {
      refetch()
    }
    if (!isLoading && isSuccess) toast({
      title: '저장 완료',
      description: '저장되었습니다!',
      status: 'success',
      duration: 9000,
      isClosable: true
    })

    if (!isLoading && isError) toast({
      title: '저장 실패',
      description: '저장 중에 오류가 발생하였습니다.',
      status: 'error',
      duration: 9000,
      isClosable: true
    })
  }, [isLoading])
  const { showpast, setIsFetching } = props
  setIsFetching(isFetchingRequest)
  if (data === undefined) {
    return (
      <Skeleton isLoaded={true} />
    )
  }

  const requestdata = removeUndefined(showpast ? data : data.filter(request => {
    const now = new Date()
    return (Date.parse(request.event.end.toString()).valueOf() > now.valueOf())
  }))

  requestdata.sort((l, r) => {
    return BooleantoNumber(Date.parse(l.event.end.toString()).valueOf() > Date.parse(r.event.end.toString()).valueOf())
  })
  console.log(requestdata)

  return (
    <Skeleton isLoaded={!isFetchingRequest}>
      <VStack spacing={3} align='normal'>
        <Table variant="simple" borderWidth="1px">
          <Thead>
            <Tr>
              <Th>학번</Th>
              <Th>이름</Th>
              <Th>신청종류</Th>
              <Th>시작시각</Th>
              <Th>종료시각</Th>
              <Th>허가여부</Th>
            </Tr>
          </Thead>
          <Tbody>
            {requestdata.map(request => {
              if (students === undefined) return null;
              const student = students.find(student => (student.id === request.event.uid))
              if (student === undefined) return null;
              if (request.event.type === "exile") {
                return (
                  <Tr key={request.id}>
                    <Td>{composeAltCode(student)}</Td>
                    <Td>{student.name}</Td>
                    <Td>{'퇴사'}</Td>
                    <Td>{DateToString(request.event.start).split(' ')[0]}</Td>
                    <Td>{DateToString(request.event.end).split(' ')[0]}</Td>
                    <Td>{StatusButton(request.status, request.id, requestpost)}</Td>
                  </Tr>
                )
              }
              else {
                return (
                  <Tr key={request.id}>
                    <Td>{composeAltCode(student)}</Td>
                    <Td>{student.name}</Td>
                    <Td>{'외출'}</Td>
                    <Td>{DateToString(request.event.start)}</Td>
                    <Td>{DateToString(request.event.end).split(' ')[2]}</Td>
                    <Td>{StatusButton(request.status, request.id, requestpost)}</Td>
                  </Tr>
                )
              }
            })}
          </Tbody>
        </Table>
      </VStack>
    </Skeleton>
  )
}

// eslint-disable-next-line
const StatusButton = (status: string, id: string, request: (arg: any) => void) => {
  if (status !== 'pending') { return (StatusToString(status)) }
  return (
    <HStack>
      <Button size="sm" colorScheme="green" onClick={() => {
        request({
          body: {
            action: 'approve',
          },
          query: id
        })
      }}
      >승인</Button>
      <Button size="sm" colorScheme="red" onClick={() => {
        request({
          body: {
            action: 'reject',
          },
          query: id
        })
      }}
      >거절</Button>
    </HStack>
  )
}